import { AfterViewInit, Component, Inject, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Culture } from '@obo-admin/admin.models';
import { OverlayService } from '@obo-common/shared/services/overlay.service';
import { OverlayConfig } from '@angular/cdk/overlay';
import { OVERLAY_S_CONFIG } from '@obo-main/injectionTokens/overlay.tokens';
import { DataGridExcelExportEvent, DataGridState } from '@obo-admin/dataGrid/models/dataGrid.models';
import { DataGridComponent } from '@obo-admin/dataGrid/dataGrid.component';
import { DataGridService } from '@obo-admin/dataGrid/services/dataGrid.service';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { Constants } from 'app/constants';
import { DataGridServiceFactory } from '@obo-admin/factories/dataGridService.factory';

@Component({
    selector: 'adm-router-culture-management',
    templateUrl: './cultureManagement.component.html'
})
export class CultureManagementComponent implements AfterViewInit {
    public selectedRow?: any;
    public excelData: any;
    public formGroup: UntypedFormGroup;
    @ViewChild('grid')
    public grid: DataGridComponent;
    public dataGridState: DataGridState = {
        filterState: [],
        sortingState: [],
        paginationState: {
            top: 10,
            skip: 0
        }
    };
    public dataGridService: DataGridService;

    @ViewChild('languageModal')
    languageModal: TemplateRef<any>;

    public get formModules() {
        return <UntypedFormArray>this.formGroup!.controls.modules;
    }

    constructor(
        @Inject('APIPREFIX') private apiPrefix: string,
        private formBuilder: UntypedFormBuilder,
        private translateService: TranslateService,
        private overlayService: OverlayService,
        @Inject(OVERLAY_S_CONFIG)
        private overlayConfig: OverlayConfig,
        private dataGridServiceFactory: DataGridServiceFactory,
        private viewContainerRef: ViewContainerRef
    ) {
        this.dataGridService = this.dataGridServiceFactory.getService(`${this.apiPrefix}Administration/Cultures`);
    }

    public createFormGroup(args: any): UntypedFormGroup {
        const item = (args.isNew ? {} : args.dataItem) as Culture;
        this.formGroup = this.formBuilder.group({
            id: item.id,
            name: [item.name, Validators.required],
            displayName: [item.displayName, Validators.required],
            modules: this.formBuilder.array(
                (item.modules || []).map((m) =>
                    this.formBuilder.group(
                        Object.entries(m).reduce((obj, [key, value]) => {
                            obj[key] = value;
                            return obj;
                        }, {} as any)
                    )
                )
            )
        });
        return this.formGroup;
    }

    public saveHandler(): void {
        const value = this.formGroup!.value;
        if (value.id) {
            this.dataGridService.update(Object.assign(this.selectedRow, value));
        } else {
            this.dataGridService.create(value);
        }
        this.overlayService.close();
    }

    public createCulture(): void {
        this.formGroup = this.createFormGroup({ isNew: true });
        this.openModal();
    }

    public selectRow(event: any): void {
        this.selectedRow = event;
        this.editHandler({ dataItem: this.selectedRow });
    }

    public editHandler({ dataItem }: any) {
        this.formGroup = this.createFormGroup({ isNew: false, dataItem });
        this.openModal();
    }

    private openModal(): void {
        this.overlayService.init(this.viewContainerRef, this.overlayConfig);
        this.overlayService.open(this.languageModal);
    }

    public cancelHandler() {
        this.overlayService.close();
        this.formGroup = undefined;
    }

    public ngAfterViewInit(): void {
        this.grid.dataGridStateChange
            .pipe(
                debounceTime(700),
                distinctUntilChanged(),
                tap((state) => (this.dataGridState = state))
            )
            .subscribe((state) => this.dataGridService.read(state));
    }

    public deleteCulture(culture: Culture): void {
        this.dataGridService.remove(culture);
    }

    public onExcelExport(e: DataGridExcelExportEvent): void {
        this.dataGridService.getAllEntries().subscribe((result) => {
            this.excelData = result.items;
            const rows = [];
            const headerCells = [];
            headerCells.push(this.translateService.instant('LANGUAGES_NAME'));
            headerCells.push(this.translateService.instant('LANGUAGES_DISPLAYNAME'));
            this.excelData[0].modules.forEach((module: any) => headerCells.push(module.name));
            rows.push(headerCells);
            this.excelData.forEach((culture: Culture) => {
                const cells = [];
                cells.push(culture.name);
                cells.push(culture.displayName);
                culture.modules.forEach((module: any) =>
                    cells.push(module.isActive ? Constants.Symbols.booleanTrue : Constants.Symbols.booleanFalse)
                );
                rows.push(cells);
            });
            e.updatedRows.next(rows);
        });
    }
}
