<adm-header [title]="'NAVIGATION_PRODUCTMANAGEMENT_DATABASELOOKUP_DESC' | translate">
    <ng-template admHeaderActionButtons>
        <button class="btn btn-fa" (click)="confirmModalRef.show()" [ngbTooltip]="'BTN_DELETE_PAGE' | translate">
            <fa-icon [icon]="['fal', 'trash-alt']" size="2x"></fa-icon>
        </button>
    </ng-template>
</adm-header>
<adm-data-grid #grid (selectionChange)="selectRow($event)" [data]="dataGridService | async"
               [selectable]="true" [dataGridState]="dataGridState">
    <adm-data-grid-column field="id" title="Id" type="number">
    </adm-data-grid-column>
    <adm-data-grid-column field="itemNumber"
                          title="{{ 'SHARED_PRODUCT_LABEL_PRODUCTNO' | translate }}">
    </adm-data-grid-column>
    <adm-data-grid-column field="labelOne" title="{{ 'DATABASELOOKUP_LABELONE' | translate }}">
    </adm-data-grid-column>
    <adm-data-grid-column field="labelTwo" title="{{ 'DATABASELOOKUP_LABELTWO' | translate }}">
    </adm-data-grid-column>
    <adm-data-grid-column field="modificationDate" title="{{ 'ADMIN_MODIFICATIONDATE' | translate }}" type="date">
        <ng-template admDataGridCell let-dataItem>
            {{ dataItem.modificationDate | date : 'short' }}
        </ng-template>
    </adm-data-grid-column>
    <adm-data-grid-column field="isActive" title="{{ 'DATABASELOOKUP_PRODUCT_ACTIVE' | translate }}">
        <ng-template admDataGridCell let-dataItem>
            {{ dataItem.isActive | yesNo | translate }}
        </ng-template>
        <ng-template admDataGridFilterMenu let-field="field" let-filter="filter">
            <adm-data-grid-filter-dropdown [filter]="filter" [field]="field" [data]="[
                                {
                                    label: (true | yesNo | translate),
                                    value: true
                                },
                                {
                                    label: (false | yesNo | translate),
                                    value: false
                                }
                            ]"></adm-data-grid-filter-dropdown>
        </ng-template>
    </adm-data-grid-column>
</adm-data-grid>

<ng-template #modal>
    <div class="modal">
        <div class="modal-header">
            <h3>{{ selectedRow.id }} {{ selectedRow.name }}</h3>
            <button class="btn-close" (click)="closeModal()"></button>
        </div>
        <div class="modal-body">
            <ul ngbNav class="nav-tabs mt-1" [destroyOnHide]="false" #selectedRowNav="ngbNav">
                <li ngbNavItem>
                    <a ngbNavLink>
                        {{ 'DATABASELOOKUP_VALUES_TITLE' | translate }}
                    </a>
                    <ng-template ngbNavContent>
                        <adm-product-values-detail [dataItems]="selectedRow.values"></adm-product-values-detail>
                    </ng-template>
                </li>
                <li ngbNavItem>
                    <a ngbNavLink>
                        {{ 'DATABASELOOKUP_REGIONS_TITLE' | translate }}
                    </a>
                    <ng-template ngbNavContent>
                        <adm-product-regions-detail [dataItems]="selectedRow.regions"></adm-product-regions-detail>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="selectedRowNav"></div>
        </div>
        <div class="modal-footer">
            <div class="d-flex justify-content-between w-100">
                <button class="btn btn-secondary" type="button" (click)="closeModal()">
                    {{ 'BTN_ABORT' | translate }}
                </button>
                @if (selectedRow?.id) {
                    <button class="btn btn-success" type="button" (click)="confirmModalRef.show()">
                        {{ 'BTN_DELETE' | translate }}
                    </button>
                }
            </div>
        </div>
    </div>
</ng-template>

<shd-confirm-modal
    #confirmModalRef
    (confirmed)="delete()"
    (dismissed)="selectedRow=undefined"
    [title]=" 'ADMIN_ARE_YOU_SURE' | translate"></shd-confirm-modal>


