import { TranslateService } from '@ngx-translate/core';
import { AccountComponent } from '@obo-account/account/account.component';
import { AccountService } from '@obo-account/account/account.service';
import { AlreadyRegisteredComponent } from '@obo-account/alreadyRegistered/alreadyRegistered.component';
import { ConfirmAccountComponent } from '@obo-account/confirmAccount/confirmAccount.component';
import { ElbridgeEntryComponent } from '@obo-account/elbridgeEntry/elbridgeEntry.component';
import { LandingComponent } from '@obo-account/landing/landing.component';
import { LoginComponent } from '@obo-account/login/login.component';
import { RegisterComponent } from '@obo-account/register/register.component';
import { RequestPasswordComponent } from '@obo-account/requestPassword/requestPassword.component';
import { ResetPasswordComponent } from '@obo-account/resetPassword/resetPassword.component';
import { AuthService } from '@obo-main/services/auth/auth.service';
import { AlertService } from '@obo-main/services/common/alert/alert.service';
import { RegionService } from '@obo-main/services/region/region.service';
import { AppSettings } from '@obo-main/utils/appSettings.service';
import { hasConstructModuleCondition, hasDownloadCondition } from '@obo-portal/models/portal.models';
import { Ng2StateDeclaration, TargetState, Transition } from '@uirouter/angular';
import { Constants } from 'app/constants';
import { of, timer } from 'rxjs';
import { RegisterWaitingComponent } from '@obo-account/registerWaiting/registerWaiting.component';

export const accountState = {
    parent: 'app',
    url: '/account',
    name: 'accountOverview',
    component: AccountComponent,
    data: {
        module: 'main',
        roles: [Constants.Role.User]
    },
    resolve: [
        {
            token: 'user',
            deps: [AccountService, AuthService],
            resolveFn: getUser
        },
        {
            token: 'passwordPattern',
            deps: [AccountService],
            resolveFn: getPasswordPattern
        },
        {
            token: 'regionList',
            deps: [RegionService],
            resolveFn: getRegionListForRegistration
        }
    ]
};

export const landingState = {
    parent: 'app',
    url: '/landing?configurator',
    redirectTo: 'login',
    name: 'landing',
    views: {
        '': {
            component: LandingComponent
        }
    },
    data: {
        module: 'main'
    },
    resolve: [
        {
            token: 'configurator',
            deps: [Transition],
            resolveFn: getConfiguratorNameFromParams
        }
    ]
};

export const loginState = {
    parent: 'landing',
    url: '/login',
    name: 'login',
    data: {
        module: 'main',
        formTitle: 'LOGINFORM_WELCOME',
        formSubTitle: 'LOGINFORM_WELCOME_SUBTITLE'
    },
    params: {
        redirectState: null,
        redirectParams: null,
        email: null
    },
    views: {
        form: {
            component: LoginComponent
        }
    },
    resolve: [
        {
            token: 'passwordPattern',
            deps: [AccountService],
            resolveFn: getPasswordPattern
        },
        {
            token: 'redirectState',
            deps: [Transition],
            resolveFn: getRedirectStateFromParams
        },
        {
            token: 'redirectParams',
            deps: [Transition],
            resolveFn: getRedirectParameters
        },
        {
            token: 'email',
            deps: [Transition],
            resolveFn: getEmailFromUrl
        }
    ]
};

export const registerState: Ng2StateDeclaration = {
    parent: 'landing',
    url: '/register',
    name: 'register',
    data: {
        module: 'main',
        formTitle: 'REGISTER_TITLE',
        formSubTitle: 'REGISTER_SUBTITLE'
    },
    params: {
        overwriteEnableRegistration: false
    },
    onEnter: checkIsRegistrationEnabled,
    views: {
        form: {
            component: RegisterComponent
        }
    },
    resolve: [
        {
            token: 'passwordPattern',
            deps: [AccountService],
            resolveFn: getPasswordPattern
        },
        {
            token: 'regionList',
            deps: [RegionService],
            resolveFn: getRegionListForRegistration
        },
        {
            token: 'selectedPortalRegion',
            deps: [RegionService],
            resolveFn: getSelectedPortalRegion
        }
    ]
};

export const eghState = {
    parent: 'landing',
    url: '/elbridge?sessionId?version?hookUrl?target',
    name: 'elbridgeEntry',
    data: {
        module: 'main'
    },
    views: {
        form: {
            component: ElbridgeEntryComponent
        }
    },
    resolve: [
        {
            token: 'sessionId',
            deps: [Transition],
            resolveFn: getElbridgeSessionIdFromUrl
        },
        {
            token: 'hookUrl',
            deps: [Transition],
            resolveFn: getElbridgeHookUrlFromUrl
        },
        {
            token: 'version',
            deps: [Transition],
            resolveFn: getElbridgeVersionFromUrl
        },
        {
            token: 'target',
            deps: [Transition],
            resolveFn: getElbridgeTargetFromUrl
        }
    ]
};

export const confirmAccountState = {
    parent: 'landing',
    url: '/confirm?id&token',
    name: 'confirmAccount',
    data: {
        module: 'main',
        formTitle: 'CONFIRMACCOUNT_TITLE',
        formSubTitle: 'CONFIRMACCOUNT_SUBTITLE'
    },
    views: {
        form: {
            component: ConfirmAccountComponent
        }
    },
    resolve: [
        {
            token: 'token',
            deps: [Transition],
            resolveFn: getTokenFromUrl
        },
        {
            token: 'userId',
            deps: [Transition],
            resolveFn: getUserIdFromUrl
        }
    ]
};

export const resetPasswordState = {
    parent: 'landing',
    url: '/resetpassword?id&token',
    name: 'resetPassword',
    data: {
        module: 'main',
        formTitle: 'RESETPASSWORD_TITLE',
        formSubTitle: 'RESETPASSWORD_SUBTITLE'
    },
    views: {
        form: {
            component: ResetPasswordComponent
        }
    },
    resolve: [
        {
            token: 'token',
            deps: [Transition],
            resolveFn: getTokenFromUrl
        },
        {
            token: 'userId',
            deps: [Transition],
            resolveFn: getUserIdFromUrl
        },
        {
            token: 'passwordPattern',
            deps: [AccountService],
            resolveFn: getPasswordPattern
        }
    ]
};

export const alreadyRegisteredState = {
    parent: 'app',
    url: '/already-registered?email',
    name: 'alreadyRegistered',
    params: {
        email: null
    },
    data: {
        module: 'main',
        formTitle: 'REGISTER_TITLE',
        formSubTitle: 'REGISTER_SUBTITLE'
    },
    views: {
        '': {
            component: AlreadyRegisteredComponent
        }
    },
    resolve: [
        {
            token: 'email',
            deps: [Transition],
            resolveFn: getEmailFromUrl
        }
    ]
};

export const registerWaitingState = {
    parent: 'landing',
    url: '/register-waiting?email',
    name: 'registerWaiting',
    params: {
        email: null
    },
    data: {
        module: 'main',
        formTitle: 'REGISTER_WAITING_TITLE'
    },
    views: {
        form: {
            component: RegisterWaitingComponent
        }
    },
    resolve: [
        {
            token: 'email',
            deps: [Transition],
            resolveFn: getEmailFromUrl
        }
    ]
};

export const requestPasswordState = {
    parent: 'landing',
    url: '/request',
    name: 'requestPassword',
    params: {
        email: null,
        requestType: undefined
    },
    data: {
        module: 'main',
        formTitle: 'REQUESTPASSWORD_TITLE',
        formSubTitle: 'REQUESTPASSWORD_SUBTITLE'
    },
    views: {
        form: {
            component: RequestPasswordComponent
        }
    },
    resolve: [
        {
            token: 'email',
            deps: [Transition],
            resolveFn: getEmailFromUrl
        },
        {
            token: 'requestType',
            deps: [Transition],
            resolveFn: getRequestTypeFromUrl
        }
    ]
};

export const inviteState = {
    parent: 'landing',
    url: '/invite?token',
    name: 'invite',
    onEnter: checkInvitation
};

export const ACCOUNT_STATES: any = [
    accountState,
    loginState,
    landingState,
    registerState,
    eghState,
    confirmAccountState,
    requestPasswordState,
    resetPasswordState,
    inviteState,
    alreadyRegisteredState,
    registerWaitingState
];

export function getConfiguratorNameFromParams(trans: Transition): string | undefined {
    return trans.params().configurator;
}

export function getUser(accountService: AccountService, authService: AuthService) {
    return accountService.getAccount(authService.isAdmin()).toPromise();
}

export function getTokenFromUrl(trans: Transition) {
    return trans.params().token;
}

export function getRequestTypeFromUrl(trans: Transition) {
    return trans.params().requestType;
}

export function getUserIdFromUrl(trans: Transition) {
    return trans.params().id;
}

export function getEmailFromUrl(trans: Transition) {
    return trans.params().email;
}

export function getElbridgeSessionIdFromUrl(trans: Transition) {
    return trans.params().sessionId ? decodeURIComponent(trans.params().sessionId) : undefined;
}

export function getElbridgeHookUrlFromUrl(trans: Transition) {
    return trans.params().hookUrl ? decodeURIComponent(trans.params().hookUrl) : undefined;
}

export function getElbridgeVersionFromUrl(trans: Transition) {
    return trans.params().version;
}

export function getElbridgeTargetFromUrl(trans: Transition) {
    return trans.params().target ? decodeURIComponent(trans.params().target) : undefined;
}

export function getPasswordPattern(accountService: AccountService) {
    return accountService.getPasswordPattern().toPromise();
}

export function getRegionList(regionService: RegionService) {
    return regionService.getRegions().toPromise();
}

export function getRegionListForRegistration(regionService: RegionService) {
    return regionService.portalRegions.filter((r) =>
        r.features.some((f) => hasDownloadCondition(f.id) || hasConstructModuleCondition(f.id))
    );
}

export function getSelectedPortalRegion(regionService: RegionService) {
    return of(regionService.selectedPortalRegion).toPromise();
}

export function checkIsRegistrationEnabled(transition: Transition) {
    const appSettings: AppSettings = transition.injector().get(AppSettings);
    const alertService: AlertService = transition.injector().get(AlertService);
    const enableRegister: boolean = transition.injector().get('ENABLEREGISTER');
    if (
        enableRegister ||
        transition.params().overwriteEnableRegistration ||
        appSettings.getItem('settings.enable_registration')
    ) {
        return true;
    }
    alertService.danger('Registration is currently disabled!');
    return transition.router.stateService.target(loginState.name);
}

export function checkInvitation(trans: Transition): TargetState {
    const oneDayInMilliseconds = 8.64e7;
    if (trans.params().token) {
        const numToken = Number.parseInt(trans.params().token);
        if (numToken + 7 * oneDayInMilliseconds > Date.now()) {
            return trans.router.stateService.target('register', {
                overwriteEnableRegistration: true
            });
        } else {
            const alertService: AlertService = trans.injector().get(AlertService);
            const translateService: TranslateService = trans.injector().get(TranslateService);
            timer(3000).subscribe(() => {
                alertService.info(translateService.instant('MAIN_REGISTRATION_INVITATIONCODE_EXPIRED'));
            });
        }
    }
    return trans.router.stateService.target('login');
}

export function getRedirectStateFromParams(transition: Transition) {
    return transition.params().redirectState;
}

export function getRedirectParameters(transition: Transition) {
    return transition.params().redirectParams;
}
