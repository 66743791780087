import {
    AfterContentInit,
    Component,
    ContentChild,
    ContentChildren,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    signal,
    SimpleChanges,
    TemplateRef
} from '@angular/core';
import {
    ColumnDef,
    ColumnFilter,
    ColumnFiltersState,
    createAngularTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    PaginationState,
    Row,
    RowData,
    SortingState,
    Table
} from '@tanstack/angular-table';
import { combineLatest, Subject } from 'rxjs';
import { skip, switchMap, takeUntil } from 'rxjs/operators';
import * as ExcelJS from 'exceljs';
import { Workbook, Worksheet } from 'exceljs';
import {
    DataGridFilter,
    DataGridColumnVisibility,
    DataGridExcelExportEvent,
    DataGridPaginationState,
    DataGridResult,
    DataGridState
} from '@obo-admin/dataGrid/models/dataGrid.models';
import { DataGridColumnComponent } from '@obo-admin/dataGrid/components/dataGridColumn/dataGridColumn.component';
import { toObservable } from '@angular/core/rxjs-interop';
import { DataGridExcelComponent } from '@obo-admin/dataGrid/components/dataGridExcel/dataGridExcel.component';
import { DataGridFilterService } from '@obo-admin/dataGrid/services/dataGridFilter.service';
import { FileSaverService } from 'ngx-filesaver';

declare module '@tanstack/angular-table' {
    //allows us to define custom properties for our columns
    interface ColumnMeta<TData extends RowData, TValue> {
        filterMenuTemplate?: TemplateRef<any>;
        cellTemplate?: TemplateRef<any>;
        filterable?: boolean;
        sortable?: boolean;
        editable?: boolean;
        width?: number;
        type?: 'number' | 'text' | 'date';
    }
}

@Component({
    selector: 'adm-data-grid',
    templateUrl: './dataGrid.component.html',
    styleUrls: ['./dataGrid.component.scss']
})
export class DataGridComponent implements OnDestroy, OnChanges, AfterContentInit, OnInit {
    @Input()
    public data: DataGridResult;
    @Input()
    public dataGridState: DataGridState;
    @Input()
    public selectable: boolean = true;
    @Input()
    public isClientSide: boolean = false;
    @Output()
    public selectionChange: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    public excelExport: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    public dataGridStateChange: EventEmitter<DataGridState> = new EventEmitter<DataGridState>();

    @ContentChildren(DataGridColumnComponent)
    public columns: QueryList<DataGridColumnComponent>;
    @ContentChild(DataGridExcelComponent)
    private excel: DataGridExcelComponent;

    private readonly _data = signal<any[]>([]);
    table: Table<any>;
    private columnDefs: ColumnDef<any, any>[];
    private fileName: string = 'excel_export.xlsx';
    readonly pageSizes: Array<number> = [10, 25, 50, 100];
    dataGridPageSize = this.pageSizes[0];
    private onDestroy = new Subject<any>();
    public filterService: DataGridFilterService = new DataGridFilterService();
    private readonly filters = signal<ColumnFiltersState>([]);
    private readonly columnVisibility = signal<DataGridColumnVisibility>({});
    private readonly sorting = signal<SortingState>([]);
    private readonly pagination = signal<PaginationState>({ pageIndex: 0, pageSize: this.pageSizes[0] });
    private readonly dataGridPagination = signal<DataGridPaginationState>({ skip: 0, top: this.pageSizes[0] });
    private readonly sortingStateObs = toObservable(this.sorting);
    private readonly dataGridPaginationObs = toObservable(this.dataGridPagination);
    private readonly dataGridFiltersObs = toObservable(this.filterService.filterState);

    constructor(private fileSaver: FileSaverService) {}

    ngOnInit(): void {
        this.dataGridPagination.set(this.dataGridState.paginationState);
        this.sorting.set(this.dataGridState.sortingState);
        this.filterService.filterState.set(this.dataGridState.filterState);
    }

    ngAfterContentInit(): void {
        this.columns.forEach((column) => column.initFilterService(this.filterService));
        this.columnDefs = this.columns.map((column) => this.createColumnDef(column));
        this.createTable();

        if (!this.isClientSide) {
            combineLatest({
                filterState: this.dataGridFiltersObs,
                sortingState: this.sortingStateObs,
                paginationState: this.dataGridPaginationObs
            })
                .pipe(
                    takeUntil(this.onDestroy),
                    switchMap(async ({ filterState, sortingState, paginationState }) =>
                        this.dataGridStateChange.emit({
                            filterState,
                            sortingState,
                            paginationState
                        })
                    )
                )
                .subscribe();

            this.dataGridFiltersObs.pipe(skip(1), takeUntil(this.onDestroy)).subscribe(() => {
                this.dataGridPagination.set({ skip: 0, top: this.pageSizes[0] });
            });
        } else {
            this.dataGridFiltersObs.pipe(takeUntil(this.onDestroy)).subscribe((filterState) => {
                const filters = filterState.map((f) => this.getClientSideFilter(f));
                this.filters.set(filters);
            });
        }

        this.columns.changes.pipe(takeUntil(this.onDestroy)).subscribe(() => {
            this.columns.forEach((column) => column.initFilterService(this.filterService));
            this.columnDefs = this.columns.map((column) => this.createColumnDef(column));
            this.table.reset();
        });
    }

    nextPage(direction: -1 | 1): void {
        if (this.isClientSide) {
            direction === -1 ? this.table.previousPage() : this.table.nextPage();
        } else {
            this.dataGridPagination.set({
                skip: this.dataGridPagination().skip + this.dataGridPagination().top * direction,
                top: this.dataGridPagination().top
            });
        }
    }

    toPage($event: any): void {
        if (this.isClientSide) {
            this.table.setPageIndex($event.target.value - 1);
        } else {
            this.dataGridPagination.set({
                skip: ($event.target.value - 1) * this.dataGridPagination().top,
                top: this.dataGridPagination().top
            });
        }
    }

    firstPage(): void {
        if (this.isClientSide) {
            this.table.firstPage();
        } else {
            this.dataGridPagination.set({
                skip: 0,
                top: this.dataGridPagination().top
            });
        }
    }

    lastPage(): void {
        if (this.isClientSide) {
            this.table.lastPage();
        } else {
            const rest = this.data?.count % this.dataGridPagination().top;
            this.dataGridPagination.set({
                skip: this.data.count - rest,
                top: this.dataGridPagination().top
            });
        }
    }

    changePageSize(pageSize: number): void {
        if (this.isClientSide) {
            this.table.setPageIndex(0);
            this.table.setPageSize(pageSize);
        } else {
            this.dataGridPagination.set({
                skip: 0,
                top: pageSize
            });
        }
    }

    get currentPage(): number {
        if (this.isClientSide) {
            return this.pagination().pageIndex + 1;
        }
        const skip = this.dataGridPagination().skip;
        const top = this.dataGridPagination().top;
        return (top + skip) / top;
    }

    get maxPageSize(): number {
        if (this.isClientSide) {
            return this.table.getPageCount();
        }
        return this.dataGridPagination().top < this.data?.count ? Math.ceil(this.data?.count / this.dataGridPagination().top) : 1;
    }

    get pageSize(): number {
        return this.isClientSide ? this.pagination().pageSize : this.dataGridPageSize;
    }

    get summary(): string {
        if (this.isClientSide) {
            const filteredRowsCount = this.table.getFilteredRowModel().rows.length;
            const visibleRowsCount =
                filteredRowsCount < this.pagination().pageSize ? filteredRowsCount : this.pagination().pageSize;
            return `${this.pagination().pageIndex * this.pagination().pageSize + 1} - ${
                visibleRowsCount + this.pagination().pageIndex * this.pagination().pageSize
            } (${this.table.getFilteredRowModel().rows.length})`;
        } else {
            return `${this.dataGridPagination().skip + 1} - ${this._data().length + this.dataGridPagination().skip} (${
                this.data?.count
            })`;
        }
    }

    private createTable(): void {
        this.table = createAngularTable(() => ({
            data: this._data(),
            columns: this.columnDefs,
            state: {
                columnVisibility: this.columnVisibility(),
                columnFilters: this.filters(),
                sorting: this.sorting(),
                pagination: this.pagination()
            },
            onColumnFiltersChange: (updater) => {
                updater instanceof Function ? this.filters.update(updater) : this.filters.set(updater);
            },
            onSortingChange: (updater) => {
                updater instanceof Function ? this.sorting.update(updater) : this.sorting.set(updater);
            },
            onPaginationChange: (updater) => {
                updater instanceof Function ? this.pagination.update(updater) : this.pagination.set(updater);
            },
            filterFns: {
                multiSelectFilter: this.multiSelectFilter
            },
            autoResetPageIndex: true,
            manualFiltering: !this.isClientSide,
            manualSorting: !this.isClientSide,
            manualPagination: !this.isClientSide,
            getCoreRowModel: getCoreRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getSortedRowModel: getSortedRowModel()
        }));
    }

    private createColumnDef(column: DataGridColumnComponent): ColumnDef<any> {
        return <ColumnDef<any>>{
            id: column.field,
            accessorKey: column.field,
            header: column.title,
            size: column.width,
            maxSize: column.width,
            minSize: column.width,
            enableResizing: false,
            filterFn: column.isMulti ? 'multiSelectFilter' : 'includesString',
            meta: {
                cellTemplate: column.cellTemplate?.templateRef,
                filterMenuTemplate: column.filterMenuTemplate?.templateRef,
                filterable: column.filterable,
                sortable: column.sortable,
                editable: column.editable,
                type: column.type
            }
        };
    }

    public exportExcel(): void {
        if (this.excel.fetchData) {
            console.log(this.dataGridPagination());
            this.excel.fetchData().subscribe((result) => {
                this.createExcelFile(result);
                console.log(this.dataGridPagination());
            });
        } else {
            this.createExcelFile();
        }
    }

    public setColumnVisibility(columnIds: Array<string>): void {
        let columnVisibility = {};
        columnIds.forEach((id) => {
            columnVisibility[id] = false;
        });
        this.columnVisibility.set(columnVisibility);
    }

    private createExcelFile(result?: DataGridResult): void {
        const excelExportEvent = new DataGridExcelExportEvent();
        let headerRow = [];
        let dataRows = [];

        if (this.excel.excelColumns.length > 0) {
            this.excel.excelColumns.forEach((column) => {
                headerRow.push(column.title);
            });

            if (result) {
                result.items.forEach((item) => {
                    let rows = [];
                    this.excel.excelColumns.forEach((column) => {
                        rows.push(item[column.field]);
                    });
                    dataRows.push(rows);
                });
            }
        }

        excelExportEvent.rows = dataRows;

        this.excelExport.emit(excelExportEvent);

        excelExportEvent.updatedRows.pipe(takeUntil(this.onDestroy)).subscribe((rows) => {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Sheet 1');
            if (headerRow.length > 0) {
                worksheet.addRow(headerRow);
            }
            worksheet.addRows(rows);
            this.setColumnWidth(worksheet);
            this.writeExcelFile(workbook, this.excel.fileName ?? this.fileName);
        });
    }

    private setColumnWidth(worksheet: Worksheet): void {
        worksheet.columns.forEach((column) => {
            let maxLength = 0;
            column.eachCell({ includeEmpty: true }, (cell) => {
                const columnLength = cell.value ? cell.value.toString().length + 3 : 10;
                if (cell.type === ExcelJS.ValueType.Date) {
                    maxLength = 20;
                } else if (columnLength > maxLength) {
                    maxLength = columnLength + 3;
                }
            });
            column.width = maxLength < 10 ? 10 : maxLength;
        });
    }

    private writeExcelFile(workbook: Workbook, fileName: string): void {
        workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            this.fileSaver.save(blob, `${fileName}`);
        });
    }

    private getClientSideFilter(dataGridColumnFilter: DataGridFilter): ColumnFilter {
        const filter = dataGridColumnFilter.filters;
        const isMulti = this.columns.find((c) => c.field === filter[0].id)?.isMulti;
        return { id: filter[0].id, value: isMulti ? filter.map((f) => f.value) : filter[0].value };
    }

    private multiSelectFilter(row: Row<any>, columnId: string, filterValue: any[], addMeta: (meta: any) => void): boolean {
        return filterValue.length === 0 || filterValue.includes(row.original[columnId]);
    }

    ngOnDestroy(): void {
        this.onDestroy.next(1);
        this.onDestroy.complete();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data) {
            this._data.set([...this.data.items]);
            if (this.table) {
                this.table.reset();
            }
        }
    }
}
