import {
    Component,
    ContentChild,
    EventEmitter,
    Inject,
    Input,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { OverlayService } from '@obo-common/shared/services/overlay.service';
import { ConfirmModalQuestionDirective } from '@obo-common/shared/components/confirmModal/confirmModalQuestion.directive';
import { OVERLAY_S_CONFIG } from '@obo-main/injectionTokens/overlay.tokens';
import { OverlayConfig } from '@angular/cdk/overlay';

@Component({
    selector: 'shd-confirm-modal',
    templateUrl: './confirmModal.component.html',
    styleUrls: ['./confirmModal.component.scss'],
    exportAs: 'confirmModal'
})
export class ConfirmModalComponent {
    @Output()
    public confirmed = new EventEmitter<void>();
    @Output()
    public dismissed = new EventEmitter<void>();

    @Input()
    public title: string;
    @Input()
    public question: string;

    @ViewChild('confirmModal') modal: TemplateRef<any>;
    @ContentChild(ConfirmModalQuestionDirective)
    questionTpl: ConfirmModalQuestionDirective;

    constructor(
        private overlayService: OverlayService,
        private viewContainerRef: ViewContainerRef,
        @Inject(OVERLAY_S_CONFIG)
        private overlayConfig: OverlayConfig
    ) {}

    public show(): void {
        this.overlayService.init(this.viewContainerRef, this.overlayConfig);
        this.overlayService.open(this.modal);
    }

    public dismiss(): void {
        this.overlayService.dismiss();
        this.dismissed.emit();
    }

    public confirm(): void {
        this.overlayService.close();
        this.confirmed.emit();
    }
}
