import { Component } from '@angular/core';
import { StateService } from '@uirouter/core';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})
export class LandingComponent {
    constructor(private stateService: StateService) {}

    public get formTitle() {
        return this.stateService.current.data && this.stateService.current.data.formTitle;
    }

    public advantages = [
        'LANDING_ADVANTAGE_PROJECTS',
        'LANDING_ADVANTAGE_PLANNINGS',
        'LANDING_ADVANTAGE_EXPORTS',
        'LANDING_ADVANTAGE_DOWNLOADS'
    ];

    public registrationProcess = [
        {
            icon: ['fas', 'file-signature'],
            text: 'LANDING_REGISTRATION_PROCESS_FILL_FORM'
        },
        {
            icon: ['fas', 'envelope'],
            text: 'LANDING_REGISTRATION_PROCESS_RECEIVE_EMAIL'
        },
        {
            icon: ['fas', 'external-link'],
            text: 'LANDING_REGISTRATION_PROCESS_CLICK_CONFIRMATION_LINK'
        },
        {
            icon: ['fas', 'user-check'],
            text: 'LANDING_REGISTRATION_PROCESS_CLICK_REGISTRATION_SUCCESSFUL'
        }
    ];
}
