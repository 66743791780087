import { OverlayConfig, OverlayModule, OverlayPositionBuilder, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { PlatformModule } from '@angular/cdk/platform';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { AccountModule } from '@obo-account/account.module';
import { SharedComponentsModule } from '@obo-common/shared/sharedComponents.module';
import { SharedDirectivesModule } from '@obo-common/shared/sharedDirectives.module';
import { SharedPipesModule } from '@obo-common/shared/sharedPipes.module';
import { DashboardModule } from '@obo-dashboard/dashboard.module';
import { SidebarModule } from '@obo-libraries/ng-sidebar/sidebar.module';
import { AlertComponent } from '@obo-main/components/alert/alert.component';
import { ElbridgeBannerComponent } from '@obo-main/components/elbridgeBanner/elbridgeBanner.component';
import { FooterComponent } from '@obo-main/components/footer/footer.component';
import { MotdComponent } from '@obo-main/components/motd/motd.component';
import { NavComponent } from '@obo-main/components/nav/nav.component';
import { ProfileCompletionReminderComponent } from '@obo-main/components/profileCompletionReminder/profileCompletionReminder.component';
import { RootComponent } from '@obo-main/components/root/root.component';
import { SpinnerComponent } from '@obo-main/components/spinner/spinner.component';
import { RegionLanguageSelectorModalComponent } from '@obo-main/components/regionLanguageSelectorModal/regionLanguageSelectorModal.component';
import {
    customCableModalFactory,
    groundingProductSelectorModalFactory,
    overlayConfigFactory,
    overlaySFactory,
    overlayXSFactory,
    overlayXLFactory,
    zoomedImageModalFactory,
    overlayLFactory,
    overlayRegionLanguageSelectionFactory,
    overlayFullScreenFactory
} from '@obo-main/factories/OverlayConfig.factory';
import { MAIN_STATES } from '@obo-main/main.states';
import { ServicesModule } from '@obo-main/services/services.module';
import { StaticModule } from '@obo-static/static.module';
import { UIRouterModule } from '@uirouter/angular';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { DebugOverlayComponent } from './components/debugOverlay/debugOverlay.component';
import { InAppNavigationComponent } from './components/inAppNavigation/inAppNavigation.component';
import { TourComponent } from './components/tour/tour.component';
import { InAppNavigationService } from './services/inAppNavigation/inAppNavigation.service';
import { GuidedTourModule } from '@obo-libraries/ngx-guided-tour/guided-tour.module';
import { GuidedTourService } from '@obo-libraries/ngx-guided-tour/guided-tour.service';
import {
    CUSTOM_CABLE_MODAL_CONFIG,
    GROUNDING_PRODUCT_SELECTOR_MODAL_CONFIG,
    OVERLAY_FULL_SCREEN_CONFIG,
    OVERLAY_L_CONFIG,
    OVERLAY_REGION_LANGUAGE_SELECTION_CONFIG,
    OVERLAY_S_CONFIG,
    OVERLAY_XL_CONFIG,
    OVERLAY_XS_CONFIG,
    ZOOMED_IMAGE_MODAL_CONFIG
} from './injectionTokens/overlay.tokens';
import { ModuleActionBarComponent } from '@obo-main/components/moduleActionBar/moduleActionBar.component';
import { ModuleActionBarService } from '@obo-main/services/moduleActionBar/moduleActionBar.service';

/**
 * App's Main Module. Only purpose is to do the bootstrapping. Also used as Container for all other modules
 */
@NgModule({
    declarations: [
        RootComponent,
        NavComponent,
        FooterComponent,
        AlertComponent,
        SpinnerComponent,
        MotdComponent,
        RegionLanguageSelectorModalComponent,
        ElbridgeBannerComponent,
        ProfileCompletionReminderComponent,
        TourComponent,
        InAppNavigationComponent,
        DebugOverlayComponent,
        ModuleActionBarComponent
    ],
    imports: [
        //Librarys
        CommonModule,
        FormsModule,
        TranslateModule,
        UIRouterModule.forChild({ states: MAIN_STATES }),
        NgbModule,
        ReactiveFormsModule,
        NgxPageScrollModule,
        OverlayModule,
        FontAwesomeModule,
        GuidedTourModule,
        // self created Modules
        ServicesModule,
        SharedDirectivesModule,
        SharedComponentsModule,
        SharedPipesModule,
        AccountModule,
        StaticModule,
        SidebarModule,
        DashboardModule,
        PlatformModule
    ],
    providers: [
        {
            provide: OverlayConfig,
            useFactory: overlayConfigFactory,
            deps: [OverlayPositionBuilder, ScrollStrategyOptions]
        },
        {
            provide: GROUNDING_PRODUCT_SELECTOR_MODAL_CONFIG,
            useFactory: groundingProductSelectorModalFactory,
            deps: [OverlayPositionBuilder, ScrollStrategyOptions]
        },
        {
            provide: CUSTOM_CABLE_MODAL_CONFIG,
            useFactory: customCableModalFactory,
            deps: [OverlayPositionBuilder, ScrollStrategyOptions]
        },
        {
            provide: ZOOMED_IMAGE_MODAL_CONFIG,
            useFactory: zoomedImageModalFactory,
            deps: [OverlayPositionBuilder, ScrollStrategyOptions]
        },
        {
            provide: OVERLAY_XS_CONFIG,
            useFactory: overlayXSFactory,
            deps: [OverlayPositionBuilder, ScrollStrategyOptions]
        },
        {
            provide: OVERLAY_S_CONFIG,
            useFactory: overlaySFactory,
            deps: [OverlayPositionBuilder, ScrollStrategyOptions]
        },
        {
            provide: OVERLAY_L_CONFIG,
            useFactory: overlayLFactory,
            deps: [OverlayPositionBuilder, ScrollStrategyOptions]
        },
        {
            provide: OVERLAY_XL_CONFIG,
            useFactory: overlayXLFactory,
            deps: [OverlayPositionBuilder, ScrollStrategyOptions]
        },
        {
            provide: OVERLAY_REGION_LANGUAGE_SELECTION_CONFIG,
            useFactory: overlayRegionLanguageSelectionFactory,
            deps: [OverlayPositionBuilder, ScrollStrategyOptions]
        },
        {
            provide: OVERLAY_FULL_SCREEN_CONFIG,
            useFactory: overlayFullScreenFactory,
            deps: [OverlayPositionBuilder, ScrollStrategyOptions]
        },
        InAppNavigationService,
        GuidedTourService,
        ModuleActionBarService
    ]
})
export class MainModule {}
