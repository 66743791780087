<div class="databaseLookup-detail">
    <adm-data-grid [data]="data" [isClientSide]="true" [dataGridState]="dataGridState">
        <adm-data-grid-column field="name"
                              title="{{ 'LANGUAGES_DISPLAYNAME' | translate }}"></adm-data-grid-column>
        <adm-data-grid-column field="price" title="{{ 'DATABASELOOKUP_REGIONS_PRICE' | translate }}">
            <ng-template admDataGridCell let-dataItem>
                {{ dataItem.price }} {{ dataItem.currencySymbol }}
            </ng-template>
        </adm-data-grid-column>
        <adm-data-grid-column field="isReleased"
                              title="{{ 'DATABASELOOKUP_REGIONS_RELEASED' | translate }}">
            <ng-template admDataGridCell let-dataItem>
                {{ dataItem.isReleased | yesNo | translate }}
            </ng-template>
            <ng-template admDataGridFilterMenu let-field="field" let-filter="filter">
                <adm-data-grid-filter-dropdown [filter]="filter" [field]="field" [data]="[
                                {
                                    label: (true | yesNo | translate),
                                    value: true
                                },
                                {
                                    label: (false | yesNo | translate),
                                    value: false
                                }
                            ]"></adm-data-grid-filter-dropdown>
            </ng-template>
        </adm-data-grid-column>
        <adm-data-grid-column field="isPriceUsed"
                              title="{{ 'DATABASELOOKUP_REGIONS_ISPRICEUSED' | translate }}">
            <ng-template admDataGridCell let-dataItem>
                {{ dataItem.isPriceUsed | yesNo | translate }}
            </ng-template>
            <ng-template admDataGridFilterMenu let-field="field" let-filter="filter">
                <adm-data-grid-filter-dropdown [filter]="filter" [field]="field" [data]="[
                                {
                                    label: (true | yesNo | translate),
                                    value: true
                                },
                                {
                                    label: (false | yesNo | translate),
                                    value: false
                                }
                            ]"></adm-data-grid-filter-dropdown>
            </ng-template>
        </adm-data-grid-column>
    </adm-data-grid>
</div>

