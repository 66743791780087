<div class="landing">
    <div class="landing-bg"></div>
    <div class="landing-panel-container">
        <div class="card card-no-shadow card-no-border mb-0">
            <div class="card-body">
                <div class="panel panel-login form-panel d-flex flex-column bg-white">
                    <h1>{{ formTitle | translate }}</h1>
                    <div class="panel-heading my-4">
                        <div class="row">
                            <div class="col-12">
                                <a uiSref="login" uiSrefActive="active" role="button" class="text-uppercase tab-item"
                                   id="login-form-link">
                                    {{ 'LOGINFORM_TITLE' | translate }}
                                </a>
                                <a
                                    uiSref="register"
                                    uiSrefActive="active"
                                    role="button"
                                    class="text-uppercase tab-item"
                                    id="register-form-link">
                                    {{ 'REGISTERFORM_TITLE' | translate }}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-12">
                                <ui-view name="form"></ui-view>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer bg-blue">
                <div class="row">
                    <div class="col-12 col-md-6 mb-3 mb-md-0">
                        <h3 class="mb-4">{{ 'LANDING_ADVANTAGES' | translate }}</h3>
                        @for (advantage of advantages; track advantage) {
                            <div class="d-flex align-items-baseline mt-3">
                        <span class="me-2">
                            <fa-icon [icon]="['fal', 'check']"></fa-icon>
                        </span>
                                <h4>{{ advantage | translate }}</h4>
                            </div>
                        }
                    </div>
                    <div class="col-12 col-md-6">
                        <h3 class="mb-4">{{ 'LANDING_REGISTRATION_PROCESS' | translate }}</h3>
                        @for (step of registrationProcess; track step; let last = $last) {
                            <div class="d-flex align-items-baseline mt-3">
                                    <span class="me-2">
                                        <fa-icon [icon]="step.icon"></fa-icon>
                                        </span>
                                <h4>{{ step.text | translate }}</h4>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
